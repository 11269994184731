<template>
  <div class="jsmb-account-information-detail">
    <h1>POLITIQUE DE PROTECTION DES DONNÉES PERSONNELLES</h1>
    <h2>À QUOI LA PRÉSENTE POLITIQUE DE PROTECTION DES DONNEES PERSONNELLES S’APPLIQUE-T-ELLE ?</h2>
    <p>La présente politique de protection des données personnelles s’applique à ce site internet, ainsi qu’à toute autre initiative en ligne appartenant à la société JE STOCKE MON BATEAU et procédant à la collecte de données personnelles (les « <strong>Supports</strong> »). Par conséquent, la présente Politique ne s’applique pas aux sites internet de tiers, y compris à ceux éventuellement mentionnés sur nos Supports par l’intermédiaire d’un lien internet.<br>
    Veuillez noter que si la législation de votre pays l’exige, la présente politique de protection des données personnelles peut être complétée par des dispositions locales que nous vous invitons vivement à consulter sur nos Supports.</p>
    <h3>QUELLES DONNEES COLLECTONS-NOUS AUPRÈS DE VOUS ?</h3>
    <p>Avec votre consentement préalable, lorsque vous utilisez nos Supports nous sommes susceptibles de collecter et de traiter tout ou partie des données suivantes :</p>
    <ul>
      <li>les données que vous fournissez (i) en remplissant des formulaires, (ii) en chargeant en ligne des contenus , (iii) en souscrivant à des services en ligne (ex : applications et pages réseaux sociaux) ou (iv) en nous adressant des correspondances :
        <ul>
          <li>prénom et nom de famille ;</li>
          <li>adresse de courrier électronique ;</li>
          <li>sexe ;</li>
          <li>numéro de téléphone ;</li>
          <li>adresse postale ;</li>
          <li>âge/date de naissance ;</li>
          <li>votre opinion sur nos produits ;</li>
          <li>informations de paiement (sur les Supports commerce électronique) ;</li>
          <li>votre historique d’achats (sur les Supports commerce électronique) ;</li>
          <li>toute demande particulière que vous pourriez nous adresser (principalement pour des besoins d’archivage) ;</li>
          <li>les photos ou vidéos de vous que vous souhaitez partager.</li>
        </ul>
      </li>
      <li>Nous sommes susceptibles de vous demander de répondre à des sondages que nous utilisons à des fins scientifiques et statistiques.</li>
    </ul>

    <h3>COLLECTONS-NOUS VOTRE ADRESSE IP ET VOS TÉMOINS DE CONNEXION (COOKIES)?</h3>
    <p>Nous sommes susceptibles de collecter votre adresse IP et votre type de navigateur à des fins d’administration de nos systèmes et/ou d’établissement de statistiques et sans identifier une quelconque autre information individuelle.</p>
    <p>Dans certains cas, nous sommes également susceptibles d’utiliser des cookies qui correspondent à des petits fichiers de lettres et de chiffres téléchargés sur votre terminal lorsque vous accédez à nos Supports. Nous utilisons principalement les cookies pour vous reconnaître lorsque vous vous connectez à nouveau à nos Supports et pour afficher un contenu susceptible de correspondre à vos centres d’intérêts.</p>
    <p>Les paramètres par défaut des navigateurs internet sont habituellement réglés de manière à accepter les cookies, mais vous pouvez facilement changer cela en modifiant les paramètres de votre navigateur. Toutefois, veuillez noter que si vous choisissez de désactiver les cookies, il se peut que certaines parties de nos Supports ne soient pas accessibles.</p>
    <p>Pour toute question concernant les cookies : contactez nous à l’adresse <a href="mailto:contact@jestockemonbateau.fr"></a>contact@jestockemonbateau.fr</p>

    <h3>COMMENT UTILISONS-NOUS VOS DONNÉES PERSONNELLES ?</h3>
    <p>Nous utilisons principalement vos données personnelles dans le cadre de la gestion de nos clients / prospects, et notamment pour répondre à vos demandes ou pour vous envoyer régulièrement des nouvelles et des informations sur nos produits, nos marques, nos opérations et/ou nos Supports susceptibles de susciter votre intérêt.</p>
    <p>Nous utilisons également vos données pour améliorer votre expérience digitale sur nos Supports : comprendre l’intérêt que vous portez à nos Supports et à leur contenu, gérer votre compte en ligne, valider votre participation à nos opérations, traiter vos paiements, faire en sorte que nos Supports soient présentés de la manière qui soit la plus adaptée à vous et à votre terminal, et vous protéger contre d’éventuelles actions frauduleuses.</p>

    <h3>COMMENT POUVEZ-VOUS ACCÉDER À VOS DONNÉES PERSONNELLES ?</h3>
    <p>Vous avez toujours la possibilité de nous contacter par courrier postal, par courrier électronique ou par téléphone (veuillez-vous reporter aux coordonnées indiquées dans les conditions d’utilisation des Supports que vous utilisez) pour consulter les données personnelles vous concernant qui sont en notre possession.</p>
    <p>Si vous identifiez une erreur parmi ces données ou si vous les jugez incomplètes ou ambigües, vous pouvez également nous demander de les corriger, de les compléter ou de les clarifier. </p>
    <p>Vous pouvez également vous opposer à ce que vos données soient utilisées à des fins de prospection commerciale ou communiquées à des tiers à cette fin. </p>
    <p>Enfin, vous pouvez nous demander de supprimer toute donnée personnelle qui vous concerne et que nous détenons. Toutefois, veuillez noter que nous sommes susceptibles de conserver une trace de vos données à des fins d’archivage (en particulier lorsque la loi nous l’impose).</p>

    <h3>PARTAGEONS-NOUS VOS DONNÉES PERSONNELLES ?</h3>
    <p>Sous réserve de ce qui est indiqué dans la présente politique de protection des données personnelles ou à moins que nous n’ayons préalablement obtenu votre consentement, nous sommes susceptibles de communiquer vos données personnelles avec des personnes employées de notre société ou nos prestataires de services (par exemple, nos agences Web qui gèrent pour notre compte certaines opérations en ligne) et/ou partenaires commerciaux pour les finalités précédemment mentionnées, et uniquement dans la limite nécessaire à l’accomplissement des tâches qui leurs sont confiées. Ces personnes pourront être amenées à vous contacter directement à partir des coordonnées que vous nous avez communiquées.</p>
    <p>Veuillez noter que nous exigeons de manière stricte de nos prestataires de services qu’ils utilisent vos données personnelles uniquement pour gérer les services que nous leur demandons de fournir. Nous demandons également à ces prestataires de toujours agir en conformité avec les lois applicables en matière de protection de données personnelles et d’accorder une attention particulière à la confidentialité de ces données.</p>


    <h3>OÙ STOCKONS-NOUS VOS DONNÉES PERSONNELLES ?</h3>
    <p>Vos données personnelles sont stockées soit dans nos bases de données soit dans celles de nos prestataires de services.</p>
    <p>Dans certains cas, et principalement pour des raisons techniques, ces bases de données peuvent être stockées sur des serveurs situés en dehors du pays (y compris en dehors de l’Union européenne) depuis lequel vous avez initialement saisi les données.</p>

    
    <h3>DONNÉES PERSONNELLES SONT-ELLES CONSERVÉES EN SÉCURITÉ ?</h3>
    <p>Nous avons pour objectif de toujours conserver vos données personnelles de la manière la plus sûre et la plus sécurisée, et uniquement pendant la durée nécessaire à la réalisation de la finalité poursuivie par le traitement. Dans cette perspective, nous prenons les mesures physiques, techniques et organisationnelles appropriées pour empêcher dans toute la mesure du possible toute altération ou perte de vos données ou tout accès non autorisé à celles-ci. </p>
    <p>Sur nos Supports de commerce électronique, nous gérons vos informations de paiement à l’aide de méthodes répondant aux normes de l’industrie (telles que PCI DSS).</p>
    <strong>Nous vous informons que cette politique de protection des données personnelles peut être modifiée par nos soins. Dans ce cas, ces modifications seront consultables sur cette page.</strong>

    <h3>DISPOSITIONS LOCALES</h3>
    <strong>France</strong>
    <p>Conformément à la loi n°78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés, vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant. Pour exercer ce droit, vous devez nous adresser une demande accompagné d’une pièce d’identité à : <a href="mailto:contact@jestockemonbateau.fr">contact@jestockemonbateau.fr.</a></p>
    
  </div>
</template>

<script>
export default {
  name: 'DatasPolitics',
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'DatasPolitics' });

    let title = (siteName+' - Conditions générales d\'utilisation - Stockage de bateaux chez les particuliers et professionnels - Hivernage Bateau - Stockage bateau hors d\'eau'),
      description = siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! La première plateforme dans le monde mettant en relation des propriétaires d\'un jardin, d\'un abri, d\'un box ou d\'un garage, avec des plaisanciers qui recherchent un endroit où stocker leur bateau.';

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
          { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'name': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'name': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
       {
          'name': 'twitter:image',
          'content': (baseUrl+require('./../../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  h1 {font-size: 1.5rem}
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  strong {
    margin-bottom: 1rem;
    font-weight: bold;
  }
  p {
    margin-bottom: 1rem;
  }
  ul{
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 1rem;
  }
  li {
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }
  input:not([type="radio"]){
    margin-bottom: 0.5rem;
    border:none;
    box-shadow: none;
  }
  textarea {
    box-shadow: none;
    border:none
  }
  input[type=radio] {
  border: 1px solid #df5051;
  padding: 0.5em;
  -webkit-appearance: none;
}
input[type=radio]:checked {
  background-size: 9px 9px;
  background-color: #df5051;
}
input[type=radio]:focus {
  outline-color: none;
}
.radio {
  font-size:0.85rem
}


</style>
